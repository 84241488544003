import { jwtDecode } from "jwt-decode";
import { defineStore } from 'pinia';
import { useUser } from "~/composables/user/useUser";
import { AuthTokenCookieName } from "~/types/consts/auth";
import { Administrator } from "../../types/consts/roles";
import { useSafe } from "~/composables/shared/useSafe";
import { useAuthorization } from "~/composables/auth/useAuthorization";

const { getUser } = useUser();
const { refreshToken, redirectToLogoutUrl } = useAuthorization();

interface AuthState {
    userId: string | undefined,
    user: IUserData | undefined
}

/** Хранилище авторизационных данных. */
export const useAuthStore = defineStore({
    id: 'auth',
    state: () : AuthState => ({
        userId: undefined,
        user: undefined
    }),
    getters:{
        isAuthorized: (state) : boolean => {
            return !!state.userId;
        },
        isAdministrator: (state) : boolean => {
            return state.user?.role?.type === Administrator;
        }
    },
    actions: {
        /** Инициализирует хранилище. */
        async init() : Promise<void> {
            /** Если иницализация уже прошла, то ничего не делаем. */
            if(this.userId) {
                return;
            }
            
            /** Получаем токен. */
            let token = useCookie(AuthTokenCookieName);
            if(!token || !token.value) {
                /** Токена нет, пытаемся отрефрешить. */
                const isRefreshed = await refreshToken();
                if(!isRefreshed) {
                    /** Не получилось - ничего не делаем, т.к. может доступ и не требуется. */
                    return;
                }

                token = useCookie(AuthTokenCookieName);
                if(!token || !token.value) {
                    /** Не получилось - ничего не делаем, т.к. может доступ и не требуется. */
                    redirectToLogoutUrl();
                }
            }

            const { tryAction } = useSafe();
            tryAction(async () => {
                const json = jwtDecode(token.value!);
                const userId = json.sub;
                const user = await getUser(userId!);
                if(user?.data) {
                    this.$patch({ userId: userId, user: user.data! });
                }
            });
        },

        /** При разлогине очищает хранилище. */
        logout() : void {
            this.$reset();
        }
    }
});