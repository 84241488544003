import { defineStore } from 'pinia';
import { useGuid } from '~/composables/shared/useGuid';

const { generateGUID } = useGuid();

/** Даныне сообщения */
interface MessageData {
    /** Заголовок */
    title: string | undefined,

    /** Содержимое */
    text: string | undefined
}

/** Данные типизированного сообщения */
interface TypesMessageData extends MessageData {
    /** Тип alert'а */
    type: "error" | "success" | "warning" | "info",
}

/** Данные уведомления */
interface NotificationData extends TypesMessageData {
    /** Идентификатор. */
    id: string,
}

/** Состояние уведомления */
interface NotificationState {
    /** Сообщения. */
    messages: NotificationData[];

    /** Таймаут на показ сообщения. */
    timeout: number
}

/** Хранилище alert'ов данных. */
export const useNotificationStore = defineStore({
    id: 'notification',
    state: () : NotificationState => ({
        messages: [],
        timeout: 5000 // 5 секунд
    }),
    actions: {
        /** Показывает информацию об успешно выполненной операции. */
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        showSuccessText ($t: any, text: string) : void {
            this.showSuccess({ title:  $t('forms.shared.notification.success'), text: text });
        },
        showSuccess (message: MessageData) : void {
            this.showNotification({ type: 'success', title: message.title, text: message.text });
        },

        /** Показывает информацию об ошибке. */
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        showErrorText ($t: any, text: string) : void {
            this.showError({ title: $t('forms.shared.notification.error'), text: text });
        },
        showError (message: MessageData) : void {
            this.showNotification({ type: 'error', title: message.title, text: message.text });
        },

        /** Показывает предупреждение. */
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        showWarningText ($t: any, text: string) : void {
            this.showWarning({ title: $t('forms.shared.notification.warning'), text: text });
        },
        showWarning (message: MessageData) : void {
            this.showNotification({ type: 'warning', title: message.title, text: message.text });
        },
        
        /** Показывает информацию. */
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        showInformationText ($t: any, text: string) : void {
            this.showInformation({ title: $t('forms.shared.notification.information'), text: text });
        },
        showInformation (message: MessageData) : void {
            this.showNotification({ type: 'info', title: message.title, text: message.text });
        },

        /** Показывает сообщение. */
        showNotification (payload: TypesMessageData): void {
            const id = generateGUID();
            this.messages.push({ id: id, type: payload.type, title: payload.title, text: payload.text });
            
            // Автоматически скрыть уведомление
            setTimeout(() => {
                this.removeNotification(id);
            }, this.timeout);
        },

        /** Удаляет сообщение. */
        removeNotification(id: string): void {
            this.messages = this.messages.filter(n => n.id !== id);
        },
    }
});