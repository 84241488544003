<script setup lang="ts">
import type { PropType } from 'vue';
    const emit = defineEmits(['click']);

    const props = defineProps({
        varient: { type: String as PropType<"elevated" | "flat" | "text" | "tonal" | "outlined" | "plain">, default: 'elevated' },
        class: { type: String, default: '' }
    });
</script>

<template>
    <v-btn :variant="props.varient" class="button" :class="props.class" @click="emit('click')">
        <slot/>
    </v-btn>
</template>

<style scoped lang="scss">
    .v-btn--variant-outlined {
        background-color: transparent;
        border: 1px solid var(--Color-Zink-200, $border-color);
    }
    .v-btn--variant-elevated{
        background: var(--Color-Zink-950, $main-active-color);
        color: var(--White-White, $main--contrast-text-color);
    }

    .button {
        --v-btn-height: 52px;

        display: flex;
        padding: 24px 26px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: var(--Border-radius-rounded-lg, 12px);

        font-size: 16px;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.16px;

        text-transform: none;
        
        @include responsive( 'mobile' ) {
            width: 100%;
        }

        &.v-btn--disabled {
            padding: var(--Padding-pa-4, 16px);
            background: var(--Color-Zink-200, $border-color);
        }
    }
</style>