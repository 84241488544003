import { Result } from "~/types/common/result";
import { useNotificationStore } from '~/stores/alert/useNotificationStore';

/** Безопасные методы. */
export const useSafe = () => {
    /**
     * Выполняет действие безопасно.
     * @param action Действие.
     * @param errorProcess Обработка ошибки.
     * @returns Результат действия.
     */
    const tryAction = async <Type>(
        action: () => Promise<Type>, 
        errorProcess: ((e: Error) => void) | undefined = undefined) 
        : Promise<Result<Type>> => {
        return await tryActionInner(action, errorProcess ? errorProcess : showError);
    }

    /**
     * Выполняет действие безопасно.
     * @param action Действие.
     * @param errorProcess Обработка ошибки.
     * @returns Результат действия.
     */
    const trySilentAction = async <Type>(action: () => Promise<Type>) : Promise<Result<Type>> => {
        return await tryActionInner(action);
    }
    
    /**
     * Выполняет действие безопасно.
     * @param action Действие.
     * @param errorProcess Обработка ошибки.
     * @returns Результат действия.
     */
    const tryActionInner = async <Type>(
        action: () => Promise<Type>, 
        errorProcess: ((e: Error) => void) | undefined = undefined) 
        : Promise<Result<Type>> => {
        try {
            const data = await action();
            return Result.success<Type>(data);
        } catch (e) {
            const error = e as Error;
            if(errorProcess){
                errorProcess(error);
            }

            return Result.error<Type>(error.message);
        }
    }

    /** Показывает сообщение об ошибке. */
    const showError = (e: Error) : void => {
        const { $i18n } = useNuxtApp();

        const store = useNotificationStore();
        store.showErrorText($i18n.t, e.message);
    } 

    return {
        tryAction,
        trySilentAction
    }
}