import { type FetchOptions } from 'ofetch';
import { useAuthStore } from '~/stores/auth/useAuthStore';
import { AuthTokenCookieName } from '~/types/consts/auth';
import { StatusCodes } from 'http-status-codes';

class ApiResponseError extends Error {
    traceId: string;
    description: string;

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    constructor(response: any) {
        super(response);
        this.traceId = response._data.traceId;
        this.message = response._data.message;
        this.description = response._data.description;
    }
}

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const refreshTokenUrl = '/identify/token/refresh';

    /** Переопределяет запрос к API. */
    const api = $fetch.create({
        credentials: 'include',
        onRequest({ options }): void | Promise<void> {
            const userAuth = useCookie(AuthTokenCookieName);
            const token = userAuth.value;

            if (token) {
                setAuthorizationHeader(token, options);
            }
        },
        async onResponseError({ response }) {
            /** Если ответ - неавторизован, и это был запрос не на обновление токена, то пытаемся обновить */
            if (response.status === StatusCodes.UNAUTHORIZED && response.url.indexOf(refreshTokenUrl) < 0) {
                await refreshToken();
            } else {
                throw new ApiResponseError(response);
            }
        },
    });

    const refreshToken = async () : Promise<void> => {
        await $fetch(config.public.securityApiBaseUrl + refreshTokenUrl, {
            server: false,
            credentials: 'include',

            async onResponseError() : Promise<void> {
                const tokenCookie = useCookie(AuthTokenCookieName);
                tokenCookie.value = null;
                
                const store = useAuthStore();
                store.logout();
                await navigateTo(config.public.logoutRedirectUrl, { external: true });
            },
        });
    }

    const setAuthorizationHeader = (token: string, options: FetchOptions) : void => {
        const authroizationHeaderName = 'Authorization';
        const authroizationString = `Bearer ${token}`;
        const headers = options.headers ||= {};
        if (Array.isArray(headers)) {
            headers.push([authroizationHeaderName, authroizationString]);
        } else if (headers instanceof Headers) {
            headers.set(authroizationHeaderName, authroizationString);
        } else {
            headers.Authorization = authroizationString;
        }
    };

    return {
        provide: {
            api
        }
    }
});