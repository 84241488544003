/** Результат выполнения */
export class Result<Type> {
    private constructor(data: Type | null = null) {
        this.data = data;
    }

    /**
     * Возвращает успешный результат
     * @param data Данные.
     * @returns Результат.
     */
    static success<Type>(data: Type | null = null) : Result<Type> {
        return new Result<Type>(data);
    }

    /**
     * Возвращает результат с ошибкой.
     * @param error Ошибка.
     * @returns Результат.
     */
    static error<Type>(error: string) : Result<Type> {
        const result = new Result<Type>();
        result.isError = true;
        result.error = error;
        return result;
    }

    /** Данные */
    data: Type | null;

    /** Признак, что результат с ошибкой. */
    isError: boolean = false;

    /** Текст ошибки. */
    error: string = '';
}