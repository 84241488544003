import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from 'vuetify'
import { ru } from 'vuetify/locale'

const mainTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#f4f4f5'
    }
}

export default defineNuxtPlugin((app) => {
    const vuetify = createVuetify({
        locale: {
            locale: 'ru',
            fallback: 'en',
            messages: { ru },
        },
        theme: {
            defaultTheme: 'mainTheme',
            themes: {
                mainTheme,
            }
        },
        ssr: true,
    });
    app.vueApp.use(vuetify);
})