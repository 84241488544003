<script setup lang="ts">
    import type { NuxtError } from '#app'
    import { StatusCodes } from 'http-status-codes';

    const props = defineProps({
        error: { type: Object as () => NuxtError, default: null }
    })

    const config = useRuntimeConfig();
    const handleError = async () : Promise<void> =>
    {
        await clearError();
        await navigateTo(config.public.logoutRedirectUrl, { external: true });
    }
</script>

<template>
  <nuxt-layout :is-error="true">
      <div v-if="props.error?.statusCode == StatusCodes.NOT_FOUND" id="error" class="not-found">
          <img src="assets/images/404.svg" alt=""></div>

      <h2 v-else-if="props.error?.statusCode == StatusCodes.FORBIDDEN" id="error">{{ $t('error.accessDenied') }}</h2>
      <h2 v-else id="error">{{ $t('error.title') }} {{ props.error?.statusCode }}</h2>
      <shared-base-button
          id ="toMainPage"
          @click="handleError()">
          {{ $t('error.mainPage') }}
      </shared-base-button>
  </nuxt-layout>
</template>

<style scoped lang="scss">
    .not-found {
        transform: scale(0.5);
    }
</style>
