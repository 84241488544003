
import { useAuthStore } from '~/stores/auth/useAuthStore';

export default defineNuxtPlugin(async () => {
    /** 
     * Токен хранится только на клиенте, на сервере его нет, т.к. авторизация происходит на сайте(другой домен). 
     * Поэтому и инициализация хранилища аутентификации должно происходить на клиенте, чтобы можно было отрефрешить токен в случае необходимости.
     */
    if (import.meta.client) {
        const store = useAuthStore();
        await store.init();
    }
});